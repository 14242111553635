import DynatraceScript from "@libs/vendors/dynatrace"
import React, { memo } from "react"
import { Helmet } from "react-helmet"

type DynatraceHeadProps = {
  id: string
}

const DynatraceHead: React.FC<DynatraceHeadProps> = (
  props: DynatraceHeadProps
) => {
  const { id } = props
  return (
    <>
      {id ? (
        <Helmet>
          <script
            data-testid="go-dynatrace"
            type="text/javascript"
            crossOrigin="anonymous"
            src={DynatraceScript.getSrcById(id)}
          ></script>
        </Helmet>
      ) : (
        ""
      )}
    </>
  )
}

export default memo(DynatraceHead)
