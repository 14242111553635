export const PAGE_DEFAULT = "Principal"
export const PAGE_QUOTATION = "Cotización"
export const PAGE_CONFIRMATION = "Confirmación"
export const PAGE_PAYMENT = "Pago"
export const PAGE_PAYMENT_AUTOEFECTIVO = "Pago-Autoefectivo"
export const PAGE_DERIVATION = "Derivación"
export const PAGE_RENOVATION = "Renovación"
export const PAGE_ERROR = "Error"

export const PAGE_CODE_DEFAULT = "landing"
export const PAGE_CODE_QUOTATION = "quotation"
export const PAGE_CODE_PAYMENT = "payment"
export const PAGE_CODE_CONFIRMATION = "thanks"
export const PAGE_CODE_ERROR = "error"
export const PAGE_CODE_DERIVATION = "derivation"
export const PAGE_CODE_RENOVATION = "renovation"
export const PAGE_CODE_PAYMENT_AUTOEFECTIVO = "payment-autoefectivo"

export const WITH_DERIVATION = 1
export const WITHOUT_DERIVATION = 0
