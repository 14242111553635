import { Channel } from "@libs/constants/channel"

export const channels: Array<Channel> = [
  {
    name: "oferta",
    context: {
      url: "/oferta",
      id: "oferta",
      gtm: "OPT-M9TZN6G",
      hotjar: "",
      partnerType: "Pacífico",
      partnerName: "Pricing01",
      additional: "Pricing 01"
    }
  },
  {
    name: "oferta2",
    context: {
      url: "/oferta2",
      id: "oferta2",
      gtm: "OPT-M9TZN6G",
      hotjar: "",
      partnerType: "Pacífico",
      partnerName: "Pricing02",
      additional: "Pricing 02"
    }
  },
  {
    name: "bcp",
    context: {
      url: "/bcp",
      id: "bcp",
      logo: "/logo-bcp.svg",
      gtm: "OPT-5DPRVRH",
      hotjar: "3490160",
      background: "#012a8d",
      color: "white",
      additional: ""
    }
  },
  {
    name: "yape",
    context: {
      url: "/yape",
      id: "yape",
      logo: "/logo-yape.png",
      background: "#5f2586",
      gtm: "OPT-MRKN26D",
      hotjar: "3490150",
      additional: ""
    }
  },
  {
    name: "yapemotos",
    context: {
      url: "/yapemotos",
      id: "yapemotos",
      gtm: "OPT-MRKN26D",
      logo: "/logo-yape.png",
      background: "#5f2586",
      color: "white",
      hotjar: "3490150",
      partnerType: "BCP",
      partnerName: "YapeMotos",
      additional: ""
    }
  },
  {
    name: "bcpmotos",
    context: {
      url: "/bcpmotos",
      id: "bcpmotos",
      gtm: "OPT-MRKN26D",
      logo: "/logo-bcp.svg",
      background: "#012a8d",
      color: "white",
      hotjar: "3490150",
      partnerType: "BCP",
      partnerName: "BcpMotos",
      additional: ""
    }
  },
  {
    name: "yapep1",
    context: {
      url: "/yapep1",
      id: "yapep1",
      gtm: "OPT-MRKN26D",
      logo: "/logo-yape.png",
      background: "#5f2586",
      hotjar: "3490150",
      color: "white",
      partnerType: "BCP",
      partnerName: "Yape-Pricing01",
      additional: "Pricing 01"
    }
  },
  {
    name: "yapep2",
    context: {
      url: "/yapep2",
      id: "yapep2",
      gtm: "OPT-MRKN26D",
      logo: "/logo-yape.png",
      background: "#5f2586",
      hotjar: "3490150",
      color: "white",
      partnerType: "BCP",
      partnerName: "Yape-Pricing02",
      additional: "Pricing 02"
    }
  },
  {
    name: "bcpp1",
    context: {
      url: "/bcpp1",
      id: "bcpp1",
      logo: "/logo-bcp.svg",
      gtm: "OPT-5DPRVRH",
      hotjar: "3490160",
      background: "#012a8d",
      color: "white",
      partnerType: "BCP",
      partnerName: "Bcp-Pricing01",
      additional: "Pricing 01"
    }
  },
  {
    name: "bcpp2",
    context: {
      url: "/bcpp2",
      id: "bcpp2",
      logo: "/logo-bcp.svg",
      gtm: "OPT-5DPRVRH",
      hotjar: "3490160",
      background: "#012a8d",
      color: "white",
      partnerType: "BCP",
      partnerName: "Pricing02",
      additional: "Pricing 02"
    }
  },
  {
    name: "clientesio",
    context: {
      url: "/clientesio",
      id: "clientesio",
      logo: "/logo_iO_200x65.svg",
      gtm: "OPT-5DPRVRH",
      hotjar: "4991474",
      background: "#000000",
      color: "white",
      partnerType: "IO",
      partnerName: "IO",
      variant: "co-branding"
    }
  }
]
