import React from "react"

import "@main/styles/global.css"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { startMSW } from "./src/mswInit"

import { RootElement } from "./src/root"

export const wrapRootElement = ({ element }) => {

  startMSW()

  return (
    <RootElement>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_API_RECAPTCHA?.toString() || ""}
      >
        {element}
      </GoogleReCaptchaProvider>
    </RootElement>
  )
}

export const onClientEntry = () => {
  if (
    process.env.GATSBY_MAINTENANCE === "1" &&
    !window.location.pathname.includes("/mantenimiento")
  ) {
    window.location.href = "/mantenimiento"
  }
}
