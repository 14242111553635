import {
  PAGE_CONFIRMATION,
  PAGE_DEFAULT,
  PAGE_PAYMENT,
  PAGE_QUOTATION,
  PAGE_RENOVATION
} from "@libs/constants/pages"
import { channels } from "@libs/constants/channels-data"
import { channels as datalayerChannels } from "@libs/datalayer/constants/datalayer.constants"
import sanitizeUrl from "@libs/utils/sanitize"

export function getPage(pathname: string) {
  const channelFound = channels.find((channel) =>
    pathname.includes(channel.context.url)
  )
  const pathnameRewrite = channelFound
    ? pathname.replace(channelFound.context.url, "")
    : pathname

  if (pathnameRewrite === "/") {
    return PAGE_DEFAULT
  }
  if (pathnameRewrite.includes("/cotizacion")) {
    return PAGE_QUOTATION
  }
  if (pathnameRewrite.includes("/gracias")) {
    return PAGE_CONFIRMATION
  }
  if (pathnameRewrite.includes("/pago")) {
    return PAGE_PAYMENT
  }
  if (pathnameRewrite.includes("/renovacion")) {
    return PAGE_RENOVATION
  }
  return ""
}

export function getQueryString() {
  const querySearch: string =
    typeof window !== "undefined"
      ? sanitizeUrl(decodeURIComponent(window.location.search))
      : ""
  return querySearch
}

type Channel = keyof typeof datalayerChannels

type Maybe<T> = T | null | undefined

// TODO: create command builder to support exclusions as well
export const isChannel = (channel: Maybe<string>, ...channelNames: Channel[]) =>
  channelNames
    .map((channelName) => datalayerChannels[channelName])
    .includes(channel ?? "")
