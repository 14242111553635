/**
 * Tipo de destinos
 * @date 2023-02-28
 */
enum DestinationType {
  NONE,
  PAGE_QUOTATION,
  PRE_PAYMENT,
  POST_PAYMENT
}

/**
 * El tipo del contexto que se guardara en el loading context.
 * @date 2023-02-28
 */
type ControlLoading = {
  isLoading: boolean
  destination?: DestinationType
}

export { DestinationType, ControlLoading }
