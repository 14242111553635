//TODO: Add variable GATSBY_IS_MSW_ON to activate msw on local development
export async function startMSW() {
  const isMswAvailable = [
    process.env.NODE_ENV,
    process.env.GATSBY_IS_MSW_ON,
    typeof window !== "undefined"
  ].every(Boolean)

  if (isMswAvailable) {
    const { worker } = await import("../mocks/browser")

    await worker.start({
      waitUntilReady: true
    })
  }
}
