import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { VscChromeClose } from "react-icons/vsc"

type ImodalProps = {
  children?: any
  onClose?: any
  hasClose?: boolean
  isOpen?: boolean
  className?: string
  classBody?: string
  modalRef?: any
  borderRadius?: number
}
const Modal: React.FC<ImodalProps> = (props: ImodalProps) => {
  const {
    children,
    onClose,
    hasClose,
    isOpen,
    className = "",
    classBody = "",
    modalRef,
    borderRadius = 8
  } = props
  const [modalContainer] = useState(
    typeof document !== `undefined` ? document.createElement("div") : null
  )

  useEffect(() => {
    let modalId = document.getElementById("modal-root")
    if (!modalId) {
      const tempEl = document.createElement("div")
      tempEl.id = "modal-root"
      tempEl.setAttribute("data-testid", "modal-root")
      document.body.append(tempEl)
      modalId = tempEl
    }
    if (modalContainer) {
      modalId.appendChild(modalContainer)
    }
    return function cleanup() {
      if (modalId && modalContainer) modalId.removeChild(modalContainer)
    }
  }, [modalContainer, isOpen])

  return isOpen && modalContainer ? (
    ReactDOM.createPortal(
      <div data-testid="modal-overlay" className={`modal-overlay ${className}`}>
        <div
          data-testid="modal-body"
          className={`modal-body ${classBody}`}
          ref={modalRef}
          style={{ borderRadius }}
        >
          {hasClose && (
            <div
              className="flex  justify-end modal-close"
              data-testid="modal-close"
            >
              <div
                className="flex items-center cursor-pointer text-sky modal-close-rewrite relative z-[9999]"
                onClick={onClose}
                aria-hidden="true"
                data-testid="modal-close-event"
              >
                <span>Cerrar</span>
                <span className="pl-2">
                  <VscChromeClose className="text-[25px]" />
                </span>
              </div>
            </div>
          )}
          {children}
        </div>
      </div>,
      modalContainer
    )
  ) : (
    <div />
  )
}

export default Modal
