export const partners = {
  pacifico: "Pacífico",
  bcp: "BCP",
  mibanco: "MiBanco",
  experiencia: "Experiencia"
}

export const partnerTypes = {
  pacifico: "Pacífico",
  bcp: "BCP",
  mibanco: "MiBanco"
}

export const partner = {
  name: "Pacifico"
}

export const channels = {
  pacifico: "",
  bcp: "bcp",
  mibanco: "soatmibanco",
  yape: "yape",
  yapemotos: "yapemotos",
  bcpmotos: "bcpmotos",
  yapep1: "yapep1",
  yapep2: "yapep2",
  bcpp1: "bcpp1",
  bcpp2: "bcpp2",
  oferta: "oferta",
  oferta2: "oferta2",
  clientesio: "clientesio"
}

export enum Events {
  VirtualPage = "virtualPage",
  virtualEvent = "virtualEvent"
}

export enum PagePath {
  Landing = "/soat-electronico/principal",
  Quotation = "/soat-electronico/cotizacion",
  Payment = "/soat-electronico/pago",
  Resume = "/soat-electronico/confirmacion",
  PaymentError = "/soat-electronico/error-pago",
  PolicyIssuanceError = "/soat-electronico/error-poliza",
  PreLoadRenovation = "/soat-renovacion/inicio-precarga",
  PaymentRenovation = "/soat-renovacion/pago",
  ConfirmationRenovation = "/soat-renovacion/confirmacion",
  PreLoadRenovationError = "/soat-renovacion/error-carga",
  PaymentRenovationError = "/soat-renovacion/error-pago",
  PolicyIssuanceRenovationError = "/soat-renovacion/error-poliza"
}

export enum PageName {
  Landing = "SOAT Electrónico NEC – Principal",
  LandingExperience = "SOAT Electrónico NEC – Principal - Experimentación",
  Quotation = "SOAT Electrónico NEC – Cotización",
  QuotationExperience = "SOAT Electrónico NEC – Cotización - Experimentación",
  Payment = "SOAT Electrónico NEC – Pago",
  PaymentExperience = "SOAT Electrónico NEC – Pago - Experimentación",
  Resume = "SOAT Electrónico NEC – Confirmación",
  ResumeExperience = "SOAT Electrónico NEC – Confirmación - Experimentación",
  PaymentError = "SOAT Electrónico NEC – Error Pago",
  PaymentErrorExperience = "SOAT Electrónico NEC – Error Pago - Experimentación",
  PolicyIssuanceError = "SOAT Electrónico NEC – Error Emisión Póliza",
  PolicyIssuanceErrorExperience = "SOAT Electrónico NEC – Error Emisión Póliza - Experimentación",
  PreLoadRenovation = "SOAT Electrónico NEC Renovación – Inicio Precarga",
  PaymentRenovation = "SOAT Electrónico NEC Renovación - Pago",
  ConfirmationRenovation = "SOAT Electrónico NEC Renovación - Confirmación",
  PreLoadRenovationError = "SOAT Electrónico NEC Renovación - Error Carga Inicial",
  PaymentRenovationError = "SOAT Electrónico NEC Renovación - Error Pago",
  PolicyIssuanceRenovationError = "SOAT Electrónico NEC Renovación - Error Emisión Póliza"
}

export enum PageNameAutoEfectivo {
  Initial = "SOAT + Autoefectivo - Inicio",
  Payment = "SOAT + Autoefectivo - Pago",
  Resume = "SOAT + Autoefectivo - Confirmación",
  PaymentError = "SOAT + Autoefectivo - Error Pago",
  PolicyIssuanceError = "SOAT + Autoefectivo - Error Emisión"
}

export enum ClassPageAutoEfectivo {
  Initial = "SOAT:SOATAutoefectivo:Inicio",
  Payment = "SOAT:SOATAutoefectivo:Pago",
  Resume = "SOAT:SOATAutoefectivo:Confirmación",
  PaymentError = "SOAT:SOATAutoefectivo:ErrorPago",
  PolicyIssuanceError = "SOAT:SOATAutoefectivo:ErrorEmisión"
}

export enum ClassPage {
  // Landing = "SOAT:SOAT:Principal",
  LandingExperience = "SOAT:SOATExperimentación:Principal",
  // Quotation = "SOAT:SOAT:Cotización",
  QuotationExperience = "SOAT:SOATExperimentación:Cotización",
  // Payment = "SOAT:SOAT:Pago",
  PaymentExperience = "SOAT:SOATExperimentación:Pago",
  // Resume = "SOAT:SOAT:Confirmación",
  ResumeExperience = "SOAT:SOATExperimentación:Confirmación",
  // PaymentError = "SOAT:SOAT:ErrorPago",
  PaymentErrorExperience = "SOAT:SOATExperimentación:ErrorPago",
  // PolicyIssuanceError = "SOAT:SOAT:ErrorEmisiónPóliza",
  PolicyIssuanceErrorExperience = "SOAT:SOATExperimentación:ErrorEmisiónPóliza",
  PreLoadRenovation = "SOAT:RENOVACION:INICIOPRECARGA",
  PaymentRenovation = "SOAT:RENOVACION:PAGO",
  ConfirmationRenovation = "SOAT:RENOVACION:CONFIRMACION",
  PreLoadRenovationError = "SOAT:RENOVACION:ErrorCargaInicial",
  PaymentRenovationError = "SOAT:RENOVACION:ErrorPago",
  PolicyIssuanceRenovationError = "SOAT:RENOVACION:ErrorEmisiónPóliza"
}

export enum Category {
  Soat = "SOAT Electrónico NEC",
  Error = "SOAT Electrónico NEC - Error"
}

export enum Action {
  Click = "click",
  Load = "load",
  Niubiz = "Niubiz",
  Business = "Negocio",
  Redirect = "Redirect",
  Modal = "Modal",
  ProcessingIssue = "Procesando Emisión"
}

export enum ZoneType {
  Soat = "SOAT"
}

export enum Zone {
  Soat = "SOAT",
  SoatExperience = "SOATExperimentación",
  QuerySoat = "Consulta SOAT",
  Renovation = "RENOVACION"
}

export enum ZoneAutoEfectivo {
  Experience = "Experimentación",
  SoatAE = "SOATAutoefectivo"
}

export enum SubZoneAutoEfectivo {
  Payment = "Pago",
  PaymentError = "Error Pago",
  EmissionError = "Error Emisión"
}

export enum SubZone {
  Landing = "Principal",
  Quotation = "Cotización",
  Payment = "Pago",
  Resume = "Confirmación",
  PaymentError = "ErrorPago",
  EmissionError = "ErrorEmisión",
  Initiation = "Inicio",
  ConfirmationRenovation = "Confirmación",
  PaymentRenovation = "Pago",
  PreLoadRenovationError = "ErrorCargaInicial",
  PaymentRenovationError = "ErrorPago",
  PolicyIssuanceRenovationError = "ErrorEmisión"
}

export enum Section {
  Form = "Formulario",
  EffectiveCart = "Auto Efectivo",
  Informative = "Informativo",
  Page = "Página",
  Redirect = "redirect",
  Modal = "Modal"
}

export enum EventType {
  Click = "click",
  Visualize = "visualizar",
  Redirect = "redirect",
  Clic = "Clic"
}

export enum ElementType {
  Button = "Botón",
  EffectiveCartCard = "Card Auto Efectivo",
  Link = "Enlace",
  ErrorPage = "PáginaError",
  Redirect = "redirect",
  Form = "Formulario",
  ButtonForm = "Formulario:Botón",
  Checkbox = "Checkbox",
  Modal = "Modal"
}

export enum ElementValueAutoEfectivo {
  Modal = "Auto Efectivo - Lo quiero",
  PaymentMethod = "Tarjeta",
  PaymentButtonName = "Pagar"
}

export enum TypePurchase {
  Regular = "Sin Renovación",
  Renovation = "Con Renovación"
}
