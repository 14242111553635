import React, { Suspense, useContext } from "react"
import { AiOutlineLoading3Quarters } from "react-icons/ai"

import ellipseCircle from "@presentation/shared/assets/images/ellipse-circle.svg"
import ellipse from "@presentation/shared/assets/images/ellipse.svg"
import loadingCloud2 from "@presentation/shared/assets/images/loading-cloud-2.svg"
import loadingCloud from "@presentation/shared/assets/images/loading-cloud.svg"
import loadingDesktop from "@presentation/shared/assets/images/loading-desktop.svg"
import loadingMobile from "@presentation/shared/assets/images/loading-mobile.svg"
import { LoadingPaymentForm } from "@presentation/shared/components/loading/loading-payment-form"

import { ctxLoading } from "@context/LoadingContext"
import {
  ControlLoading,
  DestinationType
} from "@context/default/ControlLoading"

export const Loading: React.FC = () => {
  const ctxLoadingStatus = useContext(ctxLoading)

  return ctxLoadingStatus.control.isLoading ? (
    <div
      data-testid="loading-overlay"
      className="w-full h-full fixed top-0 left-0 bg-gray-50/90 z-[999]"
      role="alert"
      aria-live="assertive"
    >
      <div className="mx-auto max-w-[1440px] h-full relative">
        <div className="h-full flex flex-col justify-center items-center">
          <div className="text-center">
            <div className="mx-auto inline-block mb-3">
              <Suspense fallback="">
                {isDestination(
                  ctxLoadingStatus.control,
                  DestinationType.PRE_PAYMENT
                ) ||
                isDestination(
                  ctxLoadingStatus.control,
                  DestinationType.POST_PAYMENT
                ) ? (
                  <div className="w-[110px] h-[110px]  relative">
                    <img
                      loading="lazy"
                      src={ellipse as string}
                      alt=""
                      className="relative  w-[110px] h-[110px]  animate-spin-normal  w-full z-20"
                    />
                    <img
                      loading="lazy"
                      src={ellipseCircle as string}
                      alt=""
                      className="absolute top-0  w-[110px] h-[110px] w-full z-10"
                    />
                  </div>
                ) : (
                  <AiOutlineLoading3Quarters className="text-4xl animate-spin-fast text-cyan" />
                )}
              </Suspense>
            </div>
          </div>
          {isDestination(
            ctxLoadingStatus.control,
            DestinationType.PAGE_QUOTATION
          ) && (
            <div className="max-w-[277px] md:max-w-[350px]">
              <p className="text-1xl text-center md:text-28xl">
                Estamos cargando{" "}
                <span className="text-info">los mejores planes</span> para tu{" "}
                <span className="text-info">SOAT ;)</span>
              </p>
            </div>
          )}

          {isDestination(
            ctxLoadingStatus.control,
            DestinationType.PRE_PAYMENT
          ) && (
            <LoadingPaymentForm>
              <>
                <p className="text-1xl text-center md:text-20xl">
                  Por favor, no cierres la ventana
                  <span className="block">
                    En breves momentos podrás iniciar tu pago.
                  </span>
                </p>
              </>
              <>
                <p className="text-1xl text-center md:text-20xl">
                  <span className="block">
                    Recuerda activar las compras por internet.
                  </span>
                </p>
              </>
            </LoadingPaymentForm>
          )}

          {isDestination(
            ctxLoadingStatus.control,
            DestinationType.POST_PAYMENT
          ) && (
            <div className="max-w-[277px] md:max-w-[350px]">
              <p className="text-1xl text-center md:text-20xl">
                Por favor, no cierres la ventana
                <span className="block">Estamos validando tu pago.</span>
              </p>
            </div>
          )}
        </div>
        <div className="opacity-70">
          <img
            loading="lazy"
            src={loadingCloud as string}
            alt=""
            className="opacity-50 absolute top-[91px] left-[48px]   sm:w-[175px] lg:hidden"
          />
          <img
            loading="lazy"
            src={loadingCloud2 as string}
            alt=""
            className="opacity-50 absolute top-[180px] right-[48px] lg:top-[131px] lg:right-[118px]   sm:w-[140px] xl:w-[275px]"
          />
          <img
            loading="lazy"
            src={loadingDesktop as string}
            alt=""
            className="opacity-50 absolute bottom-0  w-full hidden lg:block"
          />
          <img
            loading="lazy"
            src={loadingMobile as string}
            alt=""
            className="opacity-50 absolute  bottom-0 sm:-bottom-[30px] opacity-60  w-full block lg:hidden"
          />
        </div>
      </div>
    </div>
  ) : (
    <div />
  )
}

const isDestination = (
  loadingContext: ControlLoading,
  destination: DestinationType
): boolean => {
  return loadingContext.destination === destination
}
