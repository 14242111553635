//TODO: this window coercion is an indication that
// local storage operations are being used flaky and
// not in client side code as secondary effect or
// monads, should be unnecessary this kind of check

//TODO: this class as well is unjustified, it has just static
//methods so it could easily be changed for a bunch of functions instead
class LocalStorageRepository {
  static get(name: string): string | null | undefined {
    return typeof window !== "undefined" ? sessionStorage.getItem(name) : null
  }

  static set(name: string, value: string): void {
    typeof window !== "undefined" ? sessionStorage.setItem(name, value) : null
  }

  static remove(name: string): void {
    typeof window !== "undefined" ? sessionStorage.removeItem(name) : null
  }

  static get bearerToken() {
    return "Bearer " + LocalStorageRepository.get("access_token")
  }
}
export default LocalStorageRepository
