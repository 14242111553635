import React, { FC } from "react"

interface Props {
  className?: string
  enabled?: boolean
  children: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  type?: "button" | "submit"
  ordenTab?: number
}

const Button: FC<Props> = ({
  className,
  enabled = false,
  children,
  type = "button",
  onClick,
  ordenTab
}) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    onClick={onClick}
    data-testid="button"
    className={`block mx-auto  text-white mb-1 font-semibold text-lg lg:text-xl w-full rounded-3xl ${className} bg-active shadow-[1px_2px_3px_rgba(100,100,100,.7)] hover:bg-active2 duration-200 focus:outline-info`}
    tabIndex={ordenTab}
  >
    {children}
  </button>
)

export default Button
