import React, {
  PropsWithChildren,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState
} from "react"

export const LoadingPaymentForm: React.FC<PropsWithChildren> = ({
  children
}) => {
  const messages = React.Children.toArray(children)
  const indexx = useRef(0)
  const [currentMessage, setCurrentMessage] = useState<ReactNode | null>()

  useLayoutEffect(() => {
    indexx.current = 0
    setCurrentMessage(messages[indexx.current])

    const sups = setInterval(() => {
      if (indexx.current >= messages.length - 1) indexx.current = 0
      else indexx.current++
      setCurrentMessage(messages[indexx.current])
    }, 3000)
    return () => {
      clearInterval(sups)
    }
  }, [])

  return (
    <>
      {" "}
      <div className="max-w-[300px] md:max-w-[350px]">{currentMessage}</div>
    </>
  )
}
